import React, { useState, useEffect } from "react"; 
import { Card, CardMedia, CardContent, Typography, Button, Box, Divider } from "@mui/material";
import { Star, StarHalf, LocationOn } from "@mui/icons-material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// Corrected import paths
import img from "../images/logos/rvce.jpg";
import img2 from "../images/logos/iisc-bangalore.jpg";
import img3 from "../images/logos/pes.jpeg";
import img4 from "../images/logos/BMS.jpg";
import img5 from "../images/logos/ms.jpg";
import img6 from "../images/logos/dsit.webp";
import img7 from "../images/logos/ch.png";
import img10 from "../images/logos/new horizon.jpeg";

import logo1 from "../images/logos/iisc.png";
import logo2 from "../images/logos/rvcel.png";
import logo3 from "../images/logos/pesll.webp";
import logo4 from "../images/logos/bmsl.jpg";
import logo5 from "../images/logos/msl.png";
import logo6 from "../images/logos/dstil.jpg";
import logo7 from "../images/logos/chl.jpg";
import logo10 from "../images/logos/new l.png";

const colleges = [
  { name: "Indian Institute of Science (IISc)", location: "Bangalore, Karnataka", affiliation: "AICTE", course: "M.Tech", rating: 4.8, imageUrl: img2, logoUrl: logo1 },
  { name: "R.V. College of Engineering (RVCE)", location: "Bangalore, Karnataka", affiliation: "VTU, AICTE", course: "M.Tech", rating: 4.5, imageUrl: img, logoUrl: logo2 },
  { name: "PES University", location: "Bangalore, Karnataka", affiliation: "UGC, AICTE", course: "M.Tech", rating: 4.6, imageUrl: img3, logoUrl: logo3 },
  { name: "BMS College of Engineering (BMSCE)", location: "Bangalore, Karnataka", affiliation: "VTU, AICTE", course: "M.Tech", rating: 4.4, imageUrl: img4, logoUrl: logo4 },
  { name: "MS Ramaiah Institute of Technology (MSRIT)", location: "Bangalore, Karnataka", affiliation: "AICTE", course: "B.E/B.Tech", rating: 4.5, imageUrl: img5, logoUrl: logo5 },
  { name: "Dayananda Sagar College of Engineering (DSCE)", location: "Bangalore, Karnataka", affiliation: "VTU, AICTE", course: "B.E/B.Tech", rating: 4.3, imageUrl: img6, logoUrl: logo6 },
  { name: "Christ University", location: "Bangalore, Karnataka", affiliation: "UGC, AICTE", course: "M.Tech", rating: 4.4, imageUrl: img7, logoUrl: logo7 },
  { name: "New Horizon College of Engineering (NHCE)", location: "Bangalore, Karnataka", affiliation: "AICTE", course: "B.E/B.Tech", rating: 4.2, imageUrl: img10, logoUrl: logo10 },
];

const handleViewCourses = (collegeName) => {
  alert(`View courses offered by ${collegeName}`);
};

const handleDownloadBrochure = (collegeName) => {
  alert(`Downloading brochure for ${collegeName}`);
};

const handleApplyNow = (collegeName) => {
  alert(`Applying to ${collegeName}`);
};

const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.5;
  return (
    <>
      {[...Array(fullStars)].map((_, index) => (
        <Star key={index} sx={{ color: "#FFD700" }} />
      ))}
      {halfStar && <StarHalf sx={{ color: "#FFD700" }} />}
    </>
  );
};

const CollegeCards = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleClick = () => {
    window.location.href = 'https://www.getmycollege.com/colleges?state=karnataka';
  };

  return (
    <Box sx={{ padding: "20px" }}>
      
      <Typography
        variant="h4"
        sx={{
          fontSize: "26px",
          fontWeight: "700",  
          textAlign: "center",
          marginTop: "30px",  
          backgroundImage: "#4b409d",  
          WebkitBackgroundClip: "text",  
          color: "#1d1a57",  
          textTransform: "uppercase",  
          letterSpacing: "3px",  
          textShadow: "3px 3px 6px rgba(0, 0, 0, 0.1)",  
          padding: "10px 0", 
        }}
      >
        Top 10 M.Tech Colleges in Karnataka
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontSize: "18px",
          textAlign: "center",
          marginBottom: "40px",
          color: "black", 
          lineHeight: "1.8", 
          fontStyle: "italic", 
          letterSpacing: "1.5px", 
          maxWidth: "900px", 
          marginLeft: "auto", 
          marginRight: "auto", 
          padding: "0 30px", 
        }}
      >
        Explore the top M.Tech colleges in Karnataka, known for their excellent academic reputation, state-of-the-art infrastructure, and career opportunities. Choose the best college for your higher studies.
      </Typography>

      <Divider
        sx={{
          borderColor: "#1d1a57",
          borderWidth: "3px",
          marginTop: "40px",
          marginBottom: "40px",
          borderStyle: "solid",
          opacity: 0.7,
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: "25px", 
          maxWidth: "2000px",
          marginX: "auto",  // Centers the content within the container
        }}
      >
        {colleges.map((college, index) => (
          <Card
            key={index}
            sx={{
              width: "280px",
              height: "400px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.3s ease",
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateX(0)" : "translateX(-100%)",
              transition: "transform 0.6s ease-out, opacity 0.6s ease-out",
              "&:hover": { transform: "scale(1.05)" },
              backgroundColor: "#f9f9f9",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              marginBottom: "20px",  // Ensures consistent spacing between cards
            }}
          >
            <CardMedia
              component="img"
              image={college.imageUrl}
              alt={college.name}
              sx={{
                height: "120px",
                objectFit: "cover",
                width: "100%",
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center", padding: "10px" }}>
              <CardMedia
                component="img"
                image={college.logoUrl}
                alt="logo"
                sx={{
                  width: "60px",
                  height: "60px",
                  marginRight: "10px",
                }}
              />
              <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "16px" }}>
                {college.name}
              </Typography>
            </Box>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                <LocationOn sx={{ fontSize: "16px", color: "#D32F2F", marginRight: "5px" }} />
                {college.location}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                Affiliation: {college.affiliation}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                Course: {college.course}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                Rating: {renderStars(college.rating)} ({college.rating}/5)
              </Typography>
            </CardContent>
            <Box sx={{ padding: "10px", display: "flex", flexDirection: "column", gap: "10px" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1d1a57",
                  color: "white",
                  padding: "10px 20px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  textTransform: "uppercase",
                  "&:hover": {
                    backgroundColor: "#1d1a57", 
                  },
                }}
                onClick={() => handleApplyNow(college.name)}
              >
                Apply Now
              </Button>
            </Box>
          </Card>
        ))}
        <Button
          variant="outlined"
          sx={{
            backgroundColor: "#1d1a57",
            color: "white",
            padding: "10px 50px",
            fontSize: "14px",
            fontWeight: "bold",
            borderRadius: "8px",
            textTransform: "uppercase",
            minWidth: "150px",
            height: "45px",
            border: "2px solid white",
            "&:hover": {
              backgroundColor: "white",
              color: "#1d1a57",
            },
          }}
          onClick={handleClick}
        >
          Explore more Colleges
          <ArrowRightAltIcon sx={{ marginLeft: "10px" }} />
        </Button>
      </Box>
    </Box>
  );
};

export default CollegeCards;
