import React from 'react';
import { Grid, Box, Button, Menu, MenuItem, useMediaQuery, IconButton, Drawer, List, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import image from '../images/mtechcollegesinbngalore.com.png';
import HomeIcon from '@mui/icons-material/Home';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CallIcon from '@mui/icons-material/Call';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);  // State for drawer
  const theme = useTheme();

  // Breakpoints for responsive UI
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Array of colleges
  const colleges = [
    "Indian Institute of Science (IISc)",
    "R.V. College of Engineering (RVCE)",
    "PES University",
    "BMS College of Engineering (BMSCE)",
    "MS Ramaiah Institute of Technology",
    "Dayananda Sagar College of Engineering (DSCE)",
    "Christ University",
    "New Horizon College of Engineering (NHCE)",
    "Nitte Meenakshi Institute of Technology (NMIT)",
    "CMR Institute of Technology",
  ];

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  // Function to toggle the drawer menu
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box
      sx={{
        background: 'linear-gradient(to right, #4A90E2, #357ABD, #2A5D8B, #6BB9F0)',
        padding: isMobile ? '10px' : isTablet ? '15px 20px' : '20px 30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Add space between elements for better alignment
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Logo Section */}
      <Box
        component="img"
        src={image}
        alt="Logo"
        sx={{
          height: isMobile ? 70 : isTablet ? 70 : 100,
          width: isMobile ? 70 : isTablet ? 70 : 100,
          marginRight: isMobile ? 0 : 2,
          cursor: 'pointer',
          borderRadius: '20%',
          border: '2px solid white',
          objectFit: 'cover',
          display: 'block',
         
        }}
      />

      {/* Mobile Menu Toggle */}
      {isMobile && (
        <IconButton
          sx={{
            color: 'white',
            marginLeft: 'auto',
            display: 'block',
            zIndex: 1000, // Ensure it's above other elements
          }}
          onClick={handleDrawerToggle}  // Toggle drawer on click
        >
          <MenuIcon sx={{ fontSize: '2rem' }} /> {/* Larger icon for easy tapping */}
        </IconButton>
      )}

      {/* Desktop Menu */}
      {!isMobile && (
        <Grid container alignItems="center" justifyContent="flex-end" spacing={4} sx={{ flexGrow: 1 }}>
          <Grid item>
            <a href="https://www.getmycollege.com/colleges?state=karnataka" target="_blank" rel="noopener noreferrer">
              <Button sx={{ color: 'white', fontWeight: 'bold', fontSize: '18px', '&:hover': { color: '#FFD700' } }}>
                <HomeIcon sx={{ marginRight: 1 }} />
                Home
              </Button>
            </a>
          </Grid>

          {/* Other Buttons */}
          <Grid item>
            <a href="https://www.getmycollege.com/about-us" target="_blank" rel="noopener noreferrer">
              <Button sx={{ color: 'white', fontWeight: 'bold', fontSize: '18px', '&:hover': { color: '#FFD700' } }}>
                About Us
              </Button>
            </a>
          </Grid>

          {/* Dropdown for Colleges */}
          <Grid item>
            <Button
              onClick={handleMenuClick}
              sx={{ color: 'white', fontWeight: 'bold', fontSize: '18px', '&:hover': { color: '#FFD700' } }}
              endIcon={<ArrowDropDownIcon />}
            >
              Top M.Tech College
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              {colleges.map((college, index) => (
                <MenuItem key={index} onClick={handleMenuClose}>
                  {college}
                </MenuItem>
              ))}
            </Menu>
          </Grid>

          <Grid item>
            <a href="https://www.getmycollege.com/contact-us" target="_blank" rel="noopener noreferrer">
              <Button sx={{ color: 'white', fontWeight: 'bold', fontSize: '18px', '&:hover': { color: '#FFD700' } }}>
                <CallIcon sx={{ marginRight: 1 }} />
                Contact Us
              </Button>
            </a>
          </Grid>
        </Grid>
      )}

      {/* Mobile Drawer (Slides from the right) */}
      <Drawer
        anchor="right"  // Change anchor to 'right' for sliding in from the right
        open={drawerOpen}
        onClose={handleDrawerToggle}  // Close drawer when clicking outside
      >
        <Box
          sx={{
            width: 250,
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            backgroundColor: '#2A5D8B',
          }}
        >
          {/* Stack the items vertically */}
          <List sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

            <Button sx={{ color: 'white', fontWeight: 'bold', fontSize: '16px', width: '100%' }} onClick={handleDrawerToggle}>
              Home
            </Button>


            <a href="https://www.getmycollege.com/about-us" target="_blank" rel="noopener noreferrer">
              <Button sx={{ color: 'white', fontWeight: 'bold', fontSize: '16px', width: '100%' }}>
                About Us
              </Button>
            </a>

            <a href="https://www.getmycollege.com/colleges?state=karnataka" target="_blank" rel="noopener noreferrer">
            <Button
              sx={{ color: 'white', fontWeight: 'bold', fontSize: '16px', width: '100%' }}  >

              Top M.Tech College
            </Button>
</a>

            <a href="https://www.getmycollege.com/contact-us" target="_blank" rel="noopener noreferrer">
              <Button sx={{ color: 'white', fontWeight: 'bold', fontSize: '16px', width: '100%' }}>
                Contact Us
              </Button>
            </a>

          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
