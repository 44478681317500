import React, { useState } from "react";
import {
    Card,
    CardContent,
    Box,
    Grid,
    Typography,
    Button,
    Dialog,
    TextField,
    IconButton,
    Snackbar,
    CircularProgress,
    Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PeopleIcon from "@mui/icons-material/People";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import bookImage from "../images/book1.jpg";

const WhyChooseUsCard = () => (
    <Grid item xs={12}>
        <Card
            sx={{
                borderRadius: "16px",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                padding: "16px",
                margin: "16px 0",
                transition: "transform 0.3s ease-in-out",
                "&:hover": { transform: "scale(1.05)" },
            }}
        >
            <CardContent>
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    sx={{ color: "#6a11cb", marginBottom: "16px" }}
                    textAlign="center"
                >
                    Why Choose Us?
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        color: "rgba(0, 0, 0, 0.7)",
                        marginBottom: "16px",
                        lineHeight: 1.6,
                    }}
                    textAlign="center"
                >
                    Our platform offers personalized guidance, expert counseling, and real-time insights tailored to your educational journey. Here's what we provide:
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={4} textAlign="center">
                        <CheckCircleIcon sx={{ fontSize: 40, color: "#1d1a57" }} />
                        <Typography fontWeight="bold">Top College Listings</Typography>
                        <Typography variant="body2">
                            Explore the best colleges for your preferred courses.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} textAlign="center">
                        <SupportAgentIcon sx={{ fontSize: 40, color: "#1d1a57" }} />
                        <Typography fontWeight="bold">Expert Support</Typography>
                        <Typography variant="body2">
                            Get guidance from curated experts in education.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} textAlign="center">
                        <PeopleIcon sx={{ fontSize: 40, color: "#6a11cb" }} />
                        <Typography fontWeight="bold">Student Community</Typography>
                        <Typography variant="body2">
                            Connect with like-minded peers and alumni.
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
);

const CounselingUI = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formData, setFormData] = useState({ name: "", email: "", phone: "" });
    const [loading, setLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const validateForm = () => formData.name && formData.email && formData.phone;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            setSnackbarMessage("All fields are required!");
            setOpenSnackbar(true);
            return;
        }
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSnackbarMessage("Form submitted successfully!");
            setOpenSnackbar(true);
            setDialogOpen(false);
        }, 2000);
    };

    const handleSnackbarClose = () => setOpenSnackbar(false);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px", // Consistent padding for all screens
                backgroundColor: "#f9f9f9",
                minHeight: "100vh",
            }}
        >
            <Typography
                variant="h5"
                sx={{
                    textAlign: "center",
                    marginBottom: "30px",
                    fontWeight: "bold",
                    color: "#6a11cb",
                    width: "100%",
                }}
            >
                We ease your doubts with personalized Video Counseling from Experts.
            </Typography>

            <Grid container spacing={4} alignItems="center" justifyContent="center">
                {/* Image Section */}
                <Grid item xs={12} md={6} sx={{ padding: "0 16px" }}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            backgroundImage: `url(${bookImage})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            borderRadius: "12px",
                            minHeight: "300px",
                            margin:1
                        }}
                    />
                </Grid>

                {/* Card Section */}
                <Grid item xs={12} md={6} sx={{ padding: "0 16px" }}>
                    <Card
                        sx={{
                            borderRadius: "16px",
                            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                            "&:hover": { transform: "scale(1.05)" },
                            marginBottom: "10px",
                            
                        }}
                    >
                        <CardContent marginleft='10px'>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                textAlign="center"
                                sx={{ color: "#1d1a57", marginBottom: "16px" }}
                            >
                                Expert Counseling
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "rgba(0, 0, 0, 0.7)",
                                    lineHeight: 1.8,
                                    textAlign: "center",
                                }}
                            >
                                Struggling with educational decisions? Get personalized, one-on-one video
                                counseling from certified experts who provide guidance on exams, courses,
                                college admissions, and career selection. Let us help you make informed
                                choices for a brighter future.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "rgba(0, 0, 0, 0.7)",
                                    marginBottom: "16px",
                                    lineHeight: 1.8,
                                    textAlign: "center",
                                }}
                            >
                                "The journey of a thousand miles begins with a single step." Take yours with
                                expert guidance today!
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "16px",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={handleDialogOpen}
                                    sx={{
                                        background: "#1d1a57",
                                        color: "white",
                                        padding: "12px 24px",
                                        fontWeight: "bold",
                                        borderRadius: "8px",
                                        transition: "transform 0.3s",
                                        "&:hover": {
                                            background: "#1d1a57",
                                            transform: "scale(1.05)",
                                        },
                                    }}
                                >
                                    Start Now
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Why Choose Us Section */}
            <WhyChooseUsCard />

            {/* Dialog with Form */}
            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        padding: "24px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h6" fontWeight="bold">
                            Enquiry Form
                        </Typography>
                        <IconButton onClick={handleDialogClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TextField label="Name" name="name" value={formData.name} onChange={handleChange} fullWidth required />
                    <TextField label="Email" name="email" value={formData.email} onChange={handleChange} fullWidth required type="email" />
                    <TextField label="Phone" name="phone" value={formData.phone} onChange={handleChange} fullWidth required type="tel" />
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        sx={{
                            background: "linear-gradient(45deg, #0072ff, #00c6ff)",
                            color: "white",
                            padding: "12px 24px",
                            fontWeight: "bold",
                            borderRadius: "8px",
                            alignSelf: "center",
                        }}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
                    </Button>
                </Box>
            </Dialog>

            {/* Snackbar for Notifications */}
            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarMessage.includes("successfully") ? "success" : "error"}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default CounselingUI;
