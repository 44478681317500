import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Header from "./Header";
import Banner from "./Banner";
import Cards2 from '../mycomponents/Cards2'; // Make sure this is the correct relative path
import AboutUs from '../mycomponents/Aboutus';
import Footer from './Footer';
import Aboutus2 from '../mycomponents/Aboutus2';
import AutoPopup from './AutoPopup';
const Homepage = () => {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);
  const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };
  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };
  useEffect(()=>{
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 15000);
   return () => clearInterval(timeout);
  },[]);
  return (
    <Grid >
      <Header />
      <Banner />
      <Cards2 />
      <AboutUs />
      <Aboutus2 />

      <Footer />
      <AutoPopup
        isOpen={isAutoPopupOpen}
        onClose={handleCloseAutoPopup}

      />
    </Grid>
  );
};

export default Homepage;
