import React from "react";
import { Grid, Typography, Box, Paper, Container } from "@mui/material";
import { styled } from "@mui/system";
import MissionImg from "../images/mission.jpg";
import VisionImg from "../images/vision.jpg";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#fafafa",
  boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
  borderRadius: "12px",
  height: "100%",
  textAlign: "left",
}));

const AboutUs = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: { xs: "16px", sm: "32px" },
        marginTop: "30px",
      }}
    >
      <Grid container spacing={4}>
        {/* Header Section */}
        <Grid item xs={12}>
          <Box sx={{ textAlign: "left", marginBottom: "40px" }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: "#2C3E50",
                textAlign: "center",
                fontSize: { xs: "24px", sm: "32px" }, // Responsive font size
              }}
            >
              About GetMyCollege
            </Typography>
            <Typography
              variant="h6"
              sx={{
                marginTop: 2,
                color: "black",
                mx: { xs: 1, sm: 3 },
                fontSize: { xs: "14px", sm: "16px" }, // Smaller text on mobile
              }}
            >
              GetMyCollege is one of the best portals that aims to give easy access to undergraduate (UG) and
              postgraduate (PG) colleges, which includes detailed information about courses. The planning for GetMyCollege
              began in 2010 with a vision to create a one-stop platform that serves the needs of students seeking higher
              education. After years of hard work, the portal was finally launched in 2024.
              GetMyCollege has gone through many stages of development over the years to ensure that it is user-friendly
              and delivers accurate information. The journey involved things like in-depth research, meetings with educational
              institutions, and continuous alterations to meet the changing needs of students.
            </Typography>
          </Box>
        </Grid>

        {/* Mission and Vision Section */}
        <Grid container spacing={4} margin={0.7}>
          {/* Mission Section */}
          <Grid item xs={12} md={6}>
            <StyledPaper>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  color: "#34495E",
                  textAlign: "center",
                  fontSize: { xs: "20px", sm: "24px" }, // Adjust size for mobile
                }}
              >
                Our Mission
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <img
                  src={MissionImg}
                  alt="Mission"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "300px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    maxWidth: "400px", // Limit width on larger screens
                  }}
                />
              </Box>
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "14px", sm: "16px" }, // Smaller text on mobile
                  color: "#555",
                  padding: "1",
                  textAlign: "center", // Center text for mobile
                }}
              >
                Our mission is to provide innovative solutions that improve the quality of life and make the
                world a better place. We focus on customer satisfaction and sustainability in all our operations.
              </Typography>
            </StyledPaper>
          </Grid>

          {/* Vision Section */}
          <Grid item xs={12} md={6}>
            <StyledPaper>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  color: "#34495E",
                  textAlign: "center",
                  fontSize: { xs: "20px", sm: "24px" }, // Adjust size for mobile
                }}
              >
                Our Vision
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <img
                  src={VisionImg}
                  alt="Vision"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "300px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    maxWidth: "400px", // Limit width on larger screens
                  }}
                />
              </Box>
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "14px", sm: "16px" }, // Smaller text on mobile
                  color: "#555",
                  textAlign: "center", // Center text for mobile
                }}
              >
                Our vision is to be a leader in our industry by fostering innovation, creativity, and positive
                change. We aspire to expand our reach globally while maintaining the core values of integrity,
                excellence, and social responsibility.
              </Typography>
            </StyledPaper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;
