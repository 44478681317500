import { Grid, Box, TextField, Button, Typography, keyframes } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image2 from "../images/banner1.jpg";
import image3 from "../images/banner2.png";
import { CheckCircle } from "@mui/icons-material";

// Keyframes for the zoom animation
const zoomInFade = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Banner = () => {
  return (
    <Grid container sx={{ position: "relative" }}>
      {/* Image Carousel Section */}
      <Grid item xs={12}>
        <Box sx={{ position: "relative" }}>
          <Carousel
            autoPlay
            infiniteLoop
            showArrows={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            interval={3000}
            transitionTime={500}
            dynamicHeight={false}
          >
            {/* Slide 1 */}
            <Box sx={{ position: "relative" }}>
              <img
                src={image2}
                alt="Top M.Tech Colleges Campus"
                style={{
                  width: "100%",
                  height: "52vh",
                  objectFit: "cover",
                  backgroundPosition: "center",
                }}
              />
              {/* Animated Description */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: { xs: "35%", sm: "95%" }, // Adjusted bottom margin for mobile
                  left: { xs: "5%", sm: "15%" }, // Adjusted left margin for mobile
                  width: { xs: "90%", sm: "auto" }, // Full width on mobile
                  textAlign: "center",
                  color: "#fff",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                  padding: { xs: "8px", sm: "10px" }, // Smaller padding on mobile
                  borderRadius: "8px",
                  animation: `${zoomInFade} 1.5s ease-out`,
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Top M.Tech Colleges Campus
                </Typography>
                <Typography variant="body2">
                  Discover world-class facilities and expert faculty at the best M.Tech institutions.
                </Typography>
              </Box>
            </Box>

            {/* Slide 2 */}
            <Box sx={{ position: "relative" }}>
              <img
                src={image3}
                alt="Students on Campus"
                style={{
                  width: "100%",
                  height: "50vh",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: { xs: "35%", sm: "95%" }, // Adjusted bottom margin for mobile
                  left: { xs: "5%", sm: "15%" }, // Adjusted left margin for mobile
                  width: { xs: "90%", sm: "auto" }, // Full width on mobile
                  textAlign: "center",
                  color: "#fff",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  padding: { xs: "8px", sm: "10px" },
                  borderRadius: "8px",
                  animation: `${zoomInFade} 1.5s ease-out`,
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Explore the World of Engineering
                </Typography>
                <Typography variant="body2">
                  Join a vibrant community of learners and excel in cutting-edge M.Tech programs.
                </Typography>
              </Box>
            </Box>
          </Carousel>

          {/* Transparent Form */}
          <Box
            sx={{
              position: "absolute",
              bottom: "10%",
              right: "5%",
              width: "40%",
             
              maxWidth: "300px",
              padding: "13px",
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.4)",
             
              display: { xs: "none", sm: "block" },
            }}
          >
            <Typography
              variant="h6"
              component="h4"
              sx={{
                marginBottom: 1,
                textAlign: "center",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Apply for M.Tech Colleges
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginBottom: 1,
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "13px",
                color: "#000",
              }}
            >
              Kickstart your journey into the world of technology and engineering by applying for an M.Tech program today!
            </Typography>
            <TextField
              fullWidth
              placeholder="Name"
              variant="outlined"
              name="name"
              sx={{
                marginBottom: "6px",
                "& .MuiInputBase-root": {
                  color: "#000",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                },
              }}
            />
            <TextField
              fullWidth
              placeholder="Phone Number"
              variant="outlined"
              name="phone"
              sx={{
                marginBottom: "6px",
                "& .MuiInputBase-root": {
                  color: "#000",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                },
              }}
            />
            <Button
              variant="contained"
              startIcon={<CheckCircle />}
              fullWidth
              sx={{
                backgroundColor: "#1d1a57",
                "&:hover": {
                  backgroundColor: "#1d1a57",
                },
                color: "white",
                marginTop: "10px",
              }}
            >
              Apply Now
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Banner;
