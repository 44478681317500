import { Grid, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
    <Grid 
      sx={{ 
        backgroundColor: "#1d1a57", 
        padding: "20px 40px", 
        textAlign: "center", 
        boxShadow: "0 -4px 8px rgba(0, 0, 0, 0.4)",
        position: "relative",
        zIndex: 10,
        borderTop: "2px solid #D32F2F",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80px",
        animation: "fadeInUp 1s ease-in-out, bgAnimation 6s infinite alternate ease-in-out",
        
        "@keyframes fadeInUp": {
          "0%": { opacity: 0, transform: "translateY(20px)" },
          "100%": { opacity: 1, transform: "translateY(0)" }
        },
        
        "@keyframes bgAnimation": {
          "0%": { backgroundColor: "#1d1a57" },
          "50%": { backgroundColor: "#2C2A89" },
          "100%": { backgroundColor: "#1d1a57" }
        }
      }}
    >
      <Typography 
        sx={{ 
          color: "#E0E0E0", 
          fontSize: "18px", 
          fontWeight: "600", 
          letterSpacing: "1.5px", 
          textTransform: "uppercase",
          textShadow: "1px 1px 3px rgba(0,0,0,0.5)",
          animation: "pulse 2s infinite ease-in-out",
          
          "&:hover": {
            color: "#D32F2F",
            transform: "scale(1.1)",
            transition: "all 0.5s ease",
          },
          
          "@keyframes pulse": {
            "0%": { transform: "scale(1)" },
            "50%": { transform: "scale(1.05)" },
            "100%": { transform: "scale(1)" }
          }
        }}
      >
        © 2024 Top 10 Colleges. All Rights Reserved.
      </Typography>
    </Grid>
  )
}

export default Footer